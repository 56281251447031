import React from "react"
import CallOut from "../../components/CallOut"
import PdfImg from "../../img/example.jpg"
import * as styles from "./styles.module.css"

const Two = () => {
  return (
    <section className={styles.two}>
      <p className={styles.hdg}>
        Click on the report below to download the full PDF.
      </p>

      <a href="/example.pdf" download className={styles.img}>
        <img
          src={PdfImg}
          alt="Example Report"
          title="Click to download example report"
        />
      </a>

      <CallOut />
    </section>
  )
}
export default Two
