import React, { Fragment } from "react"
import Helmet from "../components/Helmet"
import Header from "../components/Header"
import One from "../examplePage/One"
import Two from "../examplePage/Two"
import Footer from "../components/Footer"

const ExamplePage = () => (
  <Fragment>
    <Helmet
      title="Example Report"
      descr="Download our example ETF Stock Report PDF to view the breakdown weightings for selected portfolio sizes."
    />
    <Header />
    <One />
    <Two />
    <Footer />
  </Fragment>
)

export default ExamplePage
