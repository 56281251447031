import React from "react"
import H1 from "../../components/H1"
import * as styles from "./styles.module.css"

const One = () => {
  return (
    <section className={styles.one}>
      <H1 text="Example Report"></H1>

      <div className={styles.grid}>
        <div>
          <span>
            The top 35 stocks represent about 72% of the indexes entire market
            capitalization!
          </span>
        </div>
      </div>
    </section>
  )
}

export default One
